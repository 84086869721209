@import url('https://fonts.googleapis.com/css?family=Proxima+Nova');
@font-face {
  font-family: '中国式手写风';
  src: local('中国式书写风'), url(Chinese.ttf) format('truetype');
}
@font-face {
  font-family: 'SF Movie Poster';
  src:local('SF Movie Poster'), url(sf.ttf) format('truetype');
}
.App {
  text-align: center;
  font-family: 华文细黑,sans-serif;
}

.imageStylePage{
  width: 80vw;
  min-width: 250pt;
  height: auto;

}

section{
  width:100vw;
  height:100vh;
  top:0;
  margin:0;
  padding:0
}

.overLay{
  display: inline;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height:100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: black;
}
.imageContainerStyle{
  position: relative;
}
.imageContainerStyle:hover .overLay{
  opacity: 0.6;
}

.words {
  font-family: SF Movie Poster;
  color: white;
  /*font-size: 95pt;*/
  font-size: 60pt;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

a{
  color: black;
  text-decoration: none;
}

a:visited{
  color:black;
}
/*animate*/

.the-arrow {
  width: 1px;
  transition: all 0.2s;
}

.the-arrow.-left {
  position: absolute;
  top: 60%;
  left: 0;
}



.the-arrow.-left > .shaft {
  width: 0;
  background-color: #4c4c4c;
}

.the-arrow.-left > .shaft:before, .the-arrow.-left > .shaft:after {
  width: 0;
  background-color: #4c4c4c;
}

.the-arrow.-left > .shaft:before {
  transform: rotate(0);
}

.the-arrow.-left > .shaft:after {
  transform: rotate(0);
}

.the-arrow.-right {
  top: 3px;
}

.the-arrow.-right > .shaft {
  width: 1px;
  transition-delay: 0.2s;
}

.the-arrow.-right > .shaft:before, .the-arrow.-right > .shaft:after {
  width: 8px;
  transition-delay: 0.3s;
  transition: all 0.5s;
}

.the-arrow.-right > .shaft:before {
  transform: rotate(40deg);
}

.the-arrow.-right > .shaft:after {
  transform: rotate(-40deg);
}

.the-arrow > .shaft {
  background-color: #4c4c4c;
  display: block;
  height: 1px;
  position: relative;
  transition: all 0.2s;
  transition-delay: 0;
  will-change: transform;
}

.the-arrow > .shaft:before, .the-arrow > .shaft:after {
  background-color: #4c4c4c;
  content: '';
  display: block;
  height: 1px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.2s;
  transition-delay: 0;
}

.the-arrow > .shaft:before {
  transform-origin: top right;
}

.the-arrow > .shaft:after {
  transform-origin: bottom right;
}

.animated-arrow {
  display: inline-block;
  color: #4c4c4c;
  font-style: italic;
  text-decoration: none;
  position: relative;
  transition: all 0.2s;
}

.animated-arrow:hover {
  color: #808080;
}

.animated-arrow:hover > .the-arrow.-left > .shaft {
  width: 64px;
  transition-delay: 0.1s;
  background-color: #808080;
}

.animated-arrow:hover > .the-arrow.-left > .shaft:before, .animated-arrow:hover > .the-arrow.-left > .shaft:after {
  width: 8px;
  transition-delay: 0.1s;
  background-color: #808080;
}

.animated-arrow:hover > .the-arrow.-left > .shaft:before {
  transform: rotate(40deg);
}

.animated-arrow:hover > .the-arrow.-left > .shaft:after {
  transform: rotate(-40deg);
}

.animated-arrow:hover > .main {
  transform: translateX(17px);
  transform: translateX(80px);
}

.animated-arrow:hover > .main > .the-arrow.-right > .shaft {
  width: 0;
  transform: translateX(200%);
  transition-delay: 0;
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:before, .animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
  width: 0;
  transition-delay: 0;
  transition: all 0.1s;
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:before {
  transform: rotate(0);
}
.animated-arrow:hover > .main > .the-arrow.-right > .shaft:after {
  transform: rotate(0);
}
.animated-arrow > .main {
  display: flex;
  align-items: center;
  transition: all 0.2s;
}
.animated-arrow > .main > .text {
  margin: 0 16px 0 0;
  line-height: 1;
}
.animated-arrow > .main > .the-arrow {
  position: relative;
}

.css-1cm5myo {
  height: auto;
  max-height: 100vh;
  max-width: 100%;
  user-select: none;
  margin: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

img.react-images__view-image.react-images__view-image--isModal.css-1cm5myo.css-1ycyyax {
  max-height: 100vh;
}

img.react-photo-gallery--gallery{
  min-width: 80vw;
}

@media only screen and (min-width: 0px) {
  .imageStyleHome{
    object-fit: cover;
    width: 25vw;
    min-width: 280pt;
    height: 70vh;
    object-position: 20%;
  }
@media only screen and (max-width: 1157px) {
  .imageStyleHome{
    object-fit: cover;
    width: 80vw;
    min-width: 250pt;
    max-height: 300pt;
    height: 70vh;
    object-position: 20%;
  }
}
}

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 3%;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
  border-radius: 25px

}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background:grey;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: black;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-x: hidden;
  position: relative;}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: black;
}

/* Wrapper for item list */
.bm-item-list {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -17px; /* Increase/Decrease this value for cross-browser compatibility */
  overflow-y: hidden;
  overflow-x: hidden;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 0) {

  *:focus {
    outline: none;
  }

}
*:focus {
  outline: none;
}


